import {
  createApp,
  nextTick,
  onMounted,
} from 'vue';
import Toast, { useToast } from 'vue-toastification';

import './style.scss';

const flash = useToast();

enum SUPPORTED_TYPES {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info'
}

document.addEventListener('DOMContentLoaded', () => {
  const flashContainer = document.getElementById('flash');

  if (!flashContainer) throw new Error('Flash container is missing');

  if (!flashContainer.dataset.messages) throw new Error('Flash container does not have data-messages');

  const flashMessages = JSON.parse(flashContainer.dataset.messages) as Record<SUPPORTED_TYPES, string>;

  const app = createApp({
    setup () {
      onMounted(async () => {
        await nextTick();

        Object
          .entries(flashMessages)
          .forEach(([type, text]) => {
            if (Object.values(SUPPORTED_TYPES).includes(type as SUPPORTED_TYPES))
              flash[type as SUPPORTED_TYPES](text);
          });
      });
    },

    template: '<div></div>',
  });

  app.use(Toast, { container: flashContainer });

  app.mount(flashContainer);
});

export default flash;
